import __request__ from 'routing/request';
interface MetricNamesResponse {
    readonly names: string[];
}
interface Metric {
}
interface MetricsSummaryResponse {
    readonly total: number;
    readonly metrics: {
        readonly [_key: string]: {};
    }[];
}
interface MetricsReadRequest {
    readonly metrics: string[];
}
interface MetricRegistry {
}
/**
 * Get all metrics
 */
export function metrics(): Promise<MetricRegistry> {
    return __request__('GET', '/system/metrics', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the values of multiple metrics at once
 */
export function multipleMetrics(Requestedmetrics: MetricsReadRequest): Promise<MetricsSummaryResponse> {
    return __request__('POST', '/system/metrics/multiple', Requestedmetrics, {}, {
        'Accept': []
    });
}
/**
 * Get all metrics keys/names
 */
export function metricNames(): Promise<MetricNamesResponse> {
    return __request__('GET', '/system/metrics/names', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all metrics of a namespace
 */
export function byNamespace(namespace: string): Promise<MetricsSummaryResponse> {
    return __request__('GET', `/system/metrics/namespace/${namespace}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single metric
 */
export function singleMetric(metricName: string): Promise<Metric> {
    return __request__('GET', `/system/metrics/${metricName}`, null, {}, {
        'Accept': ['application/json']
    });
}
