import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface BulkOperationResponse {
    readonly failures: BulkOperationFailure[];
    readonly successfully_performed: number;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface BulkOperationFailure {
    readonly failure_explanation: string;
    readonly entity_id: string;
}
interface Config {
    readonly type: string;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PageListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: EventDefinitionDto[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: string;
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface SchedulerCtx {
    readonly next_time: string;
    readonly data: JobTriggerData;
    readonly triggered_at: string;
    readonly queued_notifications: number;
    readonly is_scheduled: boolean;
    readonly status: 'runnable' | 'running' | 'complete' | 'paused' | 'error' | 'cancelled';
}
interface EventNotificationSettings {
    readonly backlog_size: number;
    readonly grace_period_ms: number;
}
interface EventFieldSpec {
    readonly data_type: 'string' | 'error';
    readonly providers: Config[];
}
interface PaginatedResponse {
    readonly [_key: string]: Object;
}
interface EventDefinitionDto {
    readonly field_spec: {
        readonly [_key: string]: EventFieldSpec;
    };
    readonly key_spec: string[];
    readonly notification_settings: EventNotificationSettings;
    readonly description: string;
    readonly _scope: string;
    readonly storage: Config[];
    readonly priority: number;
    readonly title: string;
    readonly scheduler: SchedulerCtx;
    readonly updated_at: string;
    readonly alert: boolean;
    readonly id: string;
    readonly config: EventProcessorConfig;
    readonly notifications: Config[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface EventProcessorParameters {
    readonly type: string;
}
interface BulkOperationRequest {
    readonly entity_ids: string[];
}
interface Object {
}
interface JobTriggerData {
    readonly type: string;
}
interface anyMap {
    readonly [_key: string]: unknown;
}
interface EventProcessorConfig {
    readonly type: string;
}
/**
 * List event definitions
 */
export function list(page: number = 1, per_page: number = 50, query?: string): Promise<PaginatedResponse> {
    return __request__('GET', '/events/definitions', null, { 'page': page, 'per_page': per_page, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create new event definition
 * @param schedule schedule
 */
export function create(schedule: boolean = true, JSONBody?: EventDefinitionDto): Promise<unknown> {
    return __request__('POST', '/events/definitions', JSONBody, { 'schedule': schedule }, {
        'Accept': ['application/json']
    });
}
/**
 * Delete multiple event definitions
 */
export function bulkDelete(Entitiestoremove: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/events/definitions/bulk_delete', Entitiestoremove, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enable multiple event definitions
 */
export function bulkSchedule(Eventdefinitionstoenable: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/events/definitions/bulk_schedule', Eventdefinitionstoenable, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Disable multiple event definitions
 */
export function bulkUnschedule(Eventdefinitionstodisable: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/events/definitions/bulk_unschedule', Eventdefinitionstodisable, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of event definitions
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'priority' | 'status' = 'title', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc'): Promise<PageListResponse> {
    return __request__('GET', '/events/definitions/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Validate an event definition
 */
export function validate(JSONbody: EventDefinitionDto): Promise<ValidationResult> {
    return __request__('POST', '/events/definitions/validate', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get an event definition
 */
export function get(definitionId?: string): Promise<EventDefinitionDto> {
    return __request__('GET', `/events/definitions/${definitionId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update existing event definition
 * @param schedule schedule
 */
export function update(definitionId?: string, schedule: boolean = true, JSONBody?: EventDefinitionDto): Promise<unknown> {
    return __request__('PUT', `/events/definitions/${definitionId}`, JSONBody, { 'schedule': schedule }, {
        'Accept': ['application/json']
    });
}
/**
 * Delete event definition
 */
export function remove(definitionId?: string): Promise<EventDefinitionDto> {
    return __request__('DELETE', `/events/definitions/${definitionId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Clear queued notifications for event
 */
export function clearNotificationQueue(definitionId?: string): Promise<void> {
    return __request__('PUT', `/events/definitions/${definitionId}/clear-notification-queue`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Execute event definition
 */
export function execute(parameters: EventProcessorParameters, definitionId?: string): Promise<void> {
    return __request__('POST', `/events/definitions/${definitionId}/execute`, parameters, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enable event definition
 */
export function schedule(definitionId?: string): Promise<EventDefinitionDto> {
    return __request__('PUT', `/events/definitions/${definitionId}/schedule`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Disable event definition
 */
export function unschedule(definitionId?: string): Promise<EventDefinitionDto> {
    return __request__('PUT', `/events/definitions/${definitionId}/unschedule`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get an event definition
 */
export function getWithContext(definitionId?: string): Promise<anyMap> {
    return __request__('GET', `/events/definitions/${definitionId}/with-context`, null, {}, {
        'Accept': ['application/json']
    });
}
