import __request__ from 'routing/request';
interface SuggestionsErrorDTO {
    readonly reason: string;
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface SuggestionsRequestDTO {
    readonly input: string;
    readonly timerange: TimeRange;
    readonly size: number;
    readonly field: string;
    readonly streams: string[];
}
interface SuggestionsDTO {
    readonly input: string;
    readonly sum_other_docs_count: number;
    readonly field: string;
    readonly suggestions: SuggestionEntryDTO[];
    readonly error: SuggestionsErrorDTO;
}
interface SuggestionEntryDTO {
    readonly occurrence: number;
    readonly value: string;
}
/**
 * Suggest field value
 */
export function suggestFieldValue(validationRequest?: SuggestionsRequestDTO): Promise<SuggestionsDTO> {
    return __request__('POST', '/search/suggest', validationRequest, {}, {
        'Accept': ['application/json']
    });
}
